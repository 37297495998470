const Modal = ({ setWelcomed }) => {
  const handleClick = () => {
    setWelcomed(true);
    localStorage.setItem("welcomed", true);
  };

  return (
    <>
      <input
        type="checkbox"
        id="welcome-modal"
        defaultChecked={true}
        className="modal-toggle"
      />
      <div className="modal">
        <div className="modal-box bg-neutral-100 dark:bg-neutral-800 text-neutral-800 dark:text-neutral-100">
          <h3 className="font-bold text-lg">Welcome to Notes!</h3>
          <p className="py-4">
            Your notes are saved locally on your browser. Only you can access
            them.
          </p>
          <div className="modal-action">
            <label
              htmlFor="welcome-modal"
              className="btn rounded-full"
              onClick={handleClick}
            >
              Close
            </label>
          </div>
        </div>
      </div>
    </>
  );
};

export default Modal;
