import { useState } from "react";
import { v4 as uuidv4 } from "uuid";

const CreateNote = ({ notes, setNotes }) => {
  const [body, setBody] = useState("");

  const createNote = (e) => {
    e.preventDefault();
    if (body !== "") {
      const createdOn = new Date();
      const updatedOn = new Date();
      const newNotes = [
        {
          id: uuidv4(),
          body,
          createdOn,
          updatedOn,
        },
        ...notes,
      ];
      setNotes(newNotes);
      localStorage.notes = JSON.stringify(newNotes);
      setBody("");
    }
  };

  return (
    <div className="card backdrop-blur-sm bg-white/20 rounded-lg shadow-lg my-5">
      <form onSubmit={createNote}>
        <div className="m-3 mb-[3.25rem]">
          <textarea
            className="textarea rounded-lg bg-transparent w-full"
            rows={3}
            placeholder="Add your notes here..."
            onChange={(e) => setBody(e.target.value)}
            value={body}
          ></textarea>
        </div>
        <div className="absolute bottom-3 right-3">
          <button className="btn btn-sm btn-ghost rounded-full backdrop-blur-sm bg-white/10">
            Add
          </button>
        </div>
      </form>
    </div>
  );
};

export default CreateNote;
