import { useState } from "react";

const EditNoteModal = ({ note, notes, setNotes }) => {
  const [body, setBody] = useState(note.body);

  const editNote = (e) => {
    e.preventDefault();
    const updatedOn = new Date();
    const newNotes = notes.map((originalNote) => {
      if (originalNote.id !== note.id) {
        return originalNote;
      }

      return {
        ...originalNote,
        updatedOn,
        body,
      };
    });
    setNotes(newNotes);
    localStorage.notes = JSON.stringify(newNotes);
    const modalToggle = document.getElementById(note.id);
    modalToggle.checked = false;
  };
  const resetBody = () => {
    setTimeout(() => {
      setBody(note.body);
    }, 500);
  };

  return (
    <>
      <input type="checkbox" id={note.id} className="modal-toggle" />
      <div className="modal">
        <div className="modal-box p-0 bg-neutral-100 dark:bg-neutral-800 text-neutral-800 dark:text-neutral-100">
          <div className="card rounded-lg shadow-lg relative">
            <label
              htmlFor={note.id}
              className="btn btn-sm btn-circle btn-ghost absolute right-3 top-3"
              onClick={resetBody}
            >
              ✕
            </label>
            <form onSubmit={editNote}>
              <div className="m-3 mb-[3.25rem]">
                <textarea
                  className="textarea rounded-lg bg-transparent w-full"
                  rows={5}
                  placeholder="Edit your notes here..."
                  onChange={(e) => setBody(e.target.value)}
                  value={body}
                ></textarea>
              </div>
              <div className="absolute bottom-3 right-3">
                <button className="btn btn-sm rounded-full">Edit</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditNoteModal;
