import formatDistanceToNow from "date-fns/formatDistanceToNow";

const Note = ({ note, notes, setNotes }) => {
  const deleteNote = () => {
    const newNotes = notes.filter(
      (originalNote) => originalNote.id !== note.id
    );
    setNotes(newNotes);
    localStorage.notes = JSON.stringify(newNotes);
  };

  return (
    <>
      <div className="card flex-row items-center backdrop-blur-sm bg-white/20 rounded-lg shadow-lg mb-3 p-5">
        <div className="mr-auto">
          <div className="text-justify">{note.body}</div>
          <div className="font-light text-xs mt-1">
            {note.createdOn === note.updatedOn
              ? formatDistanceToNow(new Date(note.createdOn), {
                  addSuffix: true,
                })
              : formatDistanceToNow(new Date(note.updatedOn), {
                  addSuffix: true,
                })}
          </div>
        </div>
        <div className="pl-5">
          <label htmlFor={note.id} className="btn btn-warning btn-sm m-1">
            <svg
              stroke="currentColor"
              fill="currentColor"
              strokeWidth="0"
              viewBox="0 0 512 512"
              height="1em"
              width="1em"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M290.74 93.24l128.02 128.02-277.99 277.99-114.14 12.6C11.35 513.54-1.56 500.62.14 485.34l12.7-114.22 277.9-277.88zm207.2-19.06l-60.11-60.11c-18.75-18.75-49.16-18.75-67.91 0l-56.55 56.55 128.02 128.02 56.55-56.55c18.75-18.76 18.75-49.16 0-67.91z"></path>
            </svg>
          </label>
          <button className="btn btn-error btn-sm m-1" onClick={deleteNote}>
            <svg
              stroke="currentColor"
              fill="currentColor"
              strokeWidth="0"
              viewBox="0 0 448 512"
              height="1em"
              width="1em"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M32 464a48 48 0 0 0 48 48h288a48 48 0 0 0 48-48V128H32zm272-256a16 16 0 0 1 32 0v224a16 16 0 0 1-32 0zm-96 0a16 16 0 0 1 32 0v224a16 16 0 0 1-32 0zm-96 0a16 16 0 0 1 32 0v224a16 16 0 0 1-32 0zM432 32H312l-9.4-18.7A24 24 0 0 0 281.1 0H166.8a23.72 23.72 0 0 0-21.4 13.3L136 32H16A16 16 0 0 0 0 48v32a16 16 0 0 0 16 16h416a16 16 0 0 0 16-16V48a16 16 0 0 0-16-16z"></path>
            </svg>
          </button>
        </div>
      </div>
    </>
  );
};

export default Note;
