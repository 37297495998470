import { FaMoon, FaSun } from "react-icons/fa";
import notebook from "../images/icons/notebook-dynamic-gradient.webp";

const Navbar = () => {
  const changeTheme = (isChecked) => {
    if (isChecked) {
      document.documentElement.classList.add("dark");
      localStorage.theme = "dark";
    } else {
      document.documentElement.classList.remove("dark");
      localStorage.theme = "light";
    }
  };

  return (
    <div className="navbar backdrop-blur-sm bg-white/20 rounded-lg shadow">
      <div className="flex-1">
        <div className="btn btn-ghost normal-case text-xl px-3">
          <img className="max-h-full mr-2" src={notebook} alt="Notebook Icon" />
          Notes
        </div>
      </div>
      <div className="flex-none">
        <label className="swap swap-rotate text-xl btn btn-circle btn-ghost">
          <input
            type="checkbox"
            onClick={(e) => changeTheme(e.target.checked)}
            defaultChecked={localStorage.theme === "dark" ? true : false}
          />
          <div className="swap-on">
            <FaMoon />
          </div>
          <div className="swap-off">
            <FaSun />
          </div>
        </label>
      </div>
    </div>
  );
};

export default Navbar;
