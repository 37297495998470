import Note from "./Note";

const Notes = ({ notes, setNotes }) => {
  return (
    <div>
      {notes.map((note) => (
        <Note key={note.id} note={note} notes={notes} setNotes={setNotes} />
      ))}
    </div>
  );
};

export default Notes;
