import { useEffect, useState } from "react";
import Navbar from "./components/Navbar";
import CreateNote from "./components/CreateNote";
import Notes from "./components/Notes";
import Modal from "./components/Modal";
import EditNoteModal from "./components/EditNoteModal";

function App() {
  const [welcomed, setWelcomed] = useState(true);
  const [notes, setNotes] = useState([]);

  useEffect(() => {
    localStorage.getItem("notes") !== null &&
      setNotes(JSON.parse(localStorage.getItem("notes")));
    localStorage.getItem("welcomed") === null && setWelcomed(false);
  }, []);

  return (
    <>
      <div className="bg-light dark:bg-dark bg-fixed bg-cover bg-center">
        <div className="backdrop-blur-3xl">
          <div className="text-neutral-800 dark:text-neutral-100 container min-h-screen max-w-xl mx-auto p-5">
            <Navbar />
            <CreateNote notes={notes} setNotes={setNotes} />
            <Notes notes={notes} setNotes={setNotes} />
          </div>
        </div>
      </div>
      {!welcomed && <Modal setWelcomed={setWelcomed} />}
      <div>
        {notes.map((note) => (
          <EditNoteModal
            key={note.id}
            note={note}
            notes={notes}
            setNotes={setNotes}
          />
        ))}
      </div>
    </>
  );
}

export default App;
